import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import {
  ArrowForward as ArrowForwardIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';

import style from './NotificationList.style';

const useStyles = makeStyles(style);

function NotificationList({ notifications, className, ...rest }) {
  const classes = useStyles();

  const avatars = {
    message: (
      <Avatar className={classes.avatarOrange}>
        <ChatIcon />
      </Avatar>
    )
  };

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
      disablePadding
    >
      {notifications.map((notification, i) => (
        <ListItem
          onClick={() => { window.location.href = `/preps/patients/${notification.data.prep_id}?patient=${notification.data.patient_id}`; }}
          className={classes.listItem}
          component={RouterLink}
          divider={i < notifications.length - 1}
          key={notification.id}
        >
          <ListItemAvatar>{avatars[notification.type]}</ListItemAvatar>
          <ListItemText
            // primary={`${notification.title}`}
            disableTypography
            primary={(
              <Typography
                style={{ fontWeight: (!notification.read ? 700 : 100) }}
              >
                {notification.title}
              </Typography>
            )}
            secondary={<Typography>{moment(notification.created_at).fromNow()}</Typography>}
          />
          <ArrowForwardIcon />
        </ListItem>
      ))}
    </List>
  );
}

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired
};

export default NotificationList;
