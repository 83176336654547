/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line max-len

import userTypes from '../constant/userType';

export const isDoctorProvider = (type) => type === userTypes.DoctorProvider;

export const isStaff = (type) => type === userTypes.Staff;

export const isScheduler = (type) => type === userTypes.Scheduler;

export const isAdministrators = (type) => type === userTypes.Administrators;
