/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import JoditEditor from 'jodit-react';
import { makeStyles } from '@mui/styles';
import { TextField, Grid, Paper } from '@mui/material';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import configuration from 'src/configuration';
import messageEventInsertFields from '../../constant/messageEventInsertFields';
import fontFamily from '../../constant/fontFamily';
import { GetFilename } from '../../helpers/string';
import style from './index.style';

const useStyles = makeStyles(style);

function RichEditor({
  placeholder,
  className,
  initialValue,
  onHandleEditorChange,
  hasInsertField,
  readonly,
  ...rest
}) {
  const classes = useStyles();
  const editor = useRef(null);
  const [description, setDescription] = useState(initialValue);
  const insertField = (event) => {
    if (event.target.value === 0) {
      event.persist();
    }

    const messageEventInsertFieldvalue =
      messageEventInsertFields.value[event.target.value];

    setDescription(description + messageEventInsertFieldvalue);
  };

  const handleChange = (content) => {
    let value = content?.target?.innerHTML || content || '';
    if (typeof value !== 'string') {
      value = '';
    }
    setDescription(value);
    onHandleEditorChange(value);
  };

  useEffect(() => {
    if (typeof initialValue !== 'string' || initialValue === '') {
      setDescription('');
    }

    setDescription(
      initialValue?.replaceAll(
        'https://colextra.com/be/uploads',
        'https://coloplan.com/be/uploads'
      ) ?? ''
    );
  }, [initialValue]);

  return (
    <Paper {...rest} className={clsx(classes.root, className)}>
      {hasInsertField ? (
        <Grid container spacing={2}>
          <Grid item md={5} xs={12}>
            <TextField
              className={classes.fields}
              fullWidth
              name="None"
              onChange={insertField}
              select
              value={0}
              SelectProps={{ native: true }}
              variant="outlined"
              style={{
                margin: '10px'
              }}
            >
              <option disabled />
              {messageEventInsertFields.key.map((value, index) => (
                <option value={index}>{value}</option>
              ))}
            </TextField>
          </Grid>
        </Grid>
      ) : null}
      <JoditEditor
        ref={editor}
        value={description ?? ''}
        config={{
          readonly: false,
          toolbar: true,
          uploader: {
            url: `${configuration.apiBaseUrl}/uploadImage`,
            isSuccess(resp) {
              return !resp.error;
            },
            process(resp) {
              return {
                files: resp.images,
                path: resp.path,
                baseurl: resp.baseurl,
                error: resp.error,
                message: resp.message
              };
            },
            defaultHandlerSuccess(data) {
              const fileUrl = data.baseurl + data.files;
              if (
                fileUrl.includes('.png') ||
                fileUrl.includes('.jpg') ||
                fileUrl.includes('.jpeg') ||
                fileUrl.includes('.PNG') ||
                fileUrl.includes('.JPG') ||
                fileUrl.includes('.JPEG')
              ) {
                // eslint-disable-next-line react/no-this-in-sfc
                this.jodit.selection.insertImage(fileUrl, null, 310);
              } else {
                const htmlString = `<a href='${fileUrl}'>${GetFilename(fileUrl)}</a>`;
                // eslint-disable-next-line react/no-this-in-sfc
                this.jodit.selection.insertHTML(htmlString);
              }
            }
          },
          askBeforePasteHTML: false,
          buttons: [
            'bold',
            'italic',
            '|',
            'ul',
            'ol',
            '|',
            'outdent',
            'indent',
            '|',
            'image',
            'video',
            'table',
            'link',
            '|',
            'align',
            'undo',
            'redo'
          ]
        }}
        onBlur={handleChange}
        style={{ fontFamily: fontFamily.ROBOTO_HELVETICA_ARIAL }}
      />
    </Paper>
  );
}

RichEditor.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  hasInsertField: PropTypes.bool,
  onHandleEditorChange: PropTypes.func,
  readonly: PropTypes.bool
};

export default RichEditor;
