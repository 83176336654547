import { surgeryService } from 'src/services';
import * as actionTypes from './actionTypes';

export const changeSurgeryField = (payload) => (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_SURGERY_FIELD, payload });
};

export const resetSurgeryField = (payload) => (dispatch) => {
  dispatch({ type: actionTypes.RESET_SURGERY_FIELD, payload });
};

export const saveSurgery = (payload) => (dispatch) => {
  dispatch({ type: actionTypes.SURGERY_PENDING });
  surgeryService
    .saveSurgery(payload)
    .then((data) => {
      dispatch({
        type: actionTypes.SAVE_SURGERY_SUCCESS,
        serverMessage: data.data.message
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.SAVE_SURGERY_FAILURE,
        serverMessage: error?.message
      });
    });
};

export const searchSurgeryByPrep = (prepId, page, limit, keyword = '', filter = '') => (dispatch) => {
  dispatch({ type: actionTypes.SURGERY_PENDING });
  surgeryService.getSurgeryByPrep(prepId, page, limit, keyword, filter)
    .then(
      (response) => {
        const surgeries = response.data;
        dispatch({ type: actionTypes.GET_ALL_SURGERY_SUCCESS, surgeries });
      },
      () => {
        dispatch({ type: actionTypes.GET_ALL_SURGERY_FAILURE });
      }
    );
};

export const appendSurgeryByPrep = (prepId, page, limit, keyword = '', filter = '') => (dispatch) => {
  dispatch({ type: actionTypes.APPOINTMENT_PENDING });
  surgeryService.getSurgeryByPrep(prepId, page, limit, keyword, filter)
    .then(
      (response) => {
        const surgeries = response.data;
        dispatch({ type: actionTypes.GET_ALL_SURGERY_SUCCESS_APPEND, surgeries });
      },
      () => {
        dispatch({ type: actionTypes.GET_ALL_SURGERY_FAILURE });
      }
    );
};

export const removePatientById = (payload) => (dispatch) => {
  dispatch({ type: actionTypes.SURGERY_REMOVE_PATIENT_BY_ID, payload });
};

export const cancelSurgeryTemplateByPatientId = (patientIds) => (dispatch) => {
  patientIds.forEach(async (patientId) => {
    await surgeryService.cancelSurgeryTemplate(patientId)
      .then(async (data) => {
        dispatch({
          type: actionTypes.SAVE_SURGERY_SUCCESS,
          serverMessage: data.data.message
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SAVE_SURGERY_FAILURE,
          serverMessage: error?.message
        });
      });
  });
};
