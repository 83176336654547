import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { LinearProgress } from '@mui/material';
import Topbar from './Topbar';
import style from './index.style';

const useStyles = makeStyles(style);

function Patient() {
  const classes = useStyles();

  return (
    <div>
      <Topbar />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default Patient;
