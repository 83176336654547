import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  account: stringFormatter.formatUrl('/users/me'),
  changeProfile: stringFormatter.formatUrl('/users/update'),
  changePassword: stringFormatter.formatUrl('/users/changePassword'),
};

const getAccount = () => axiosInstance.get(endpoints.account);

const saveChange = (isChangeProfile, changeValue) => {
  if (isChangeProfile) {
    return axiosInstance.put(endpoints.changeProfile, changeValue);
  }

  return axiosInstance.put(endpoints.changePassword, changeValue);
};

// eslint-disable-next-line import/prefer-default-export
export const accountServices = {
  getAccount,
  saveChange
};
