/* eslint-disable */
import * as actionTypes from 'src/actions/actionTypes';
import moment from 'moment';
import { initialState } from './prepReducer.init';
import { sortMessageEvents } from '../helpers/function';

const prepReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_PREP_SUCCESS:
      return {
        ...state,
        selectedPrep: action.selectedPrep
      };

    // CHANGE_PREP_FIELD
    case actionTypes.REMOVE_CHANGE_PREP_FIELD:
      return {
        ...state,
        prep: {
          ...initialState.prep
        }
      };

    case actionTypes.CHANGE_PREP_FIELD:
      // eslint-disable-next-line no-case-declarations
      const messageEventNumber = action.MessageEventNumber;
      // eslint-disable-next-line no-case-declarations
      const {
        name, value, level, childrenNumber, subChildrenNumber
      } = action;

      if (messageEventNumber === null) {
        return {
          ...state,
          prep: {
            ...state.prep,
            [name]: value
          }
        };
      }

      if (level === null) {
        // eslint-disable-next-line no-param-reassign
        state.prep.messageEvents[messageEventNumber] = {
          ...state.prep.messageEvents[messageEventNumber],
          [name]: value
        };

        return state;
      }

      if (level === 1) {
        // eslint-disable-next-line no-param-reassign
        state.prep.messageEvents[messageEventNumber].messageEventQuery = {
          ...state.prep.messageEvents[messageEventNumber].messageEventQuery,
          [name]: value
        };

        return state;
      }
      if (level === 2) {
        // eslint-disable-next-line no-param-reassign
        state.prep.messageEvents[messageEventNumber].messageEventQuery.children[childrenNumber] = {
          ...state.prep
            .messageEvents[messageEventNumber]
            .messageEventQuery.children[childrenNumber],
          [name]: value
        };

        return state;
      }
      if (level === 3) {
        // eslint-disable-next-line no-param-reassign
        state.prep.messageEvents[messageEventNumber]
          .messageEventQuery.children[childrenNumber].children[subChildrenNumber] = {
            ...state.prep.messageEvents[messageEventNumber].messageEventQuery
              .children[childrenNumber]
              .children[subChildrenNumber],
            [name]: value
          };

        return state;
      }
      return state;

    // GET_PREPS_SUCCESS
    case actionTypes.GET_PREPS_SUCCESS:
      return {
        ...state,
        preps: action.preps
      };

    case actionTypes.GET_PREPS_FAILURE:
      return {
        ...state,
        preps: []
      };

    // CREATE_PREP_SUCCESS
    case actionTypes.CREATE_PREP_SUCCESS:
      return {
        ...state,
        prep: {
          ...state.prep,
          localId: action.data.localId,
          name: action.data.name,
          businessId: action.data.businessId,
          description: action.data.description,
          startDate: moment(action.data.startDate).format('MM-DD-YYYY'),
          endDate: moment(action.data.endDate).format('MM-DD-YYYY'),
          fee: action.data.fee,
          privacyAllowClinicalTeamToInviteOthers:
          action.data.privacyAllowClinicalTeamToInviteOthers,
          privacyDoubleBlindStudy: action.data.privacyDoubleBlindStudy,
          messageEvents: [
            ...state.prep.messageEvents
          ]
        }
      };
    case actionTypes.RESET_VALIDATION:
      return {
        ...state,
        hasServerError: false,
        serverErrorMessage: ''
      };
    case actionTypes.CREATE_PREP:
      return {
        ...state,
        hasServerError: false,
        serverErrorMessage: ''
      };

    case actionTypes.CREATE_PREP_FAILURE:
      return {
        ...state,
        hasServerError: true,
        serverErrorMessage: action.serverErrorMessage
      };

    case actionTypes.GET_PREP_BY_ID_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      let messageEventsTemp = [...action.data.messageEvents];
      messageEventsTemp = messageEventsTemp.map((item) =>
        // eslint-disable-next-line no-param-reassign
        // item.procedureTimeType = 1;// time marker
        item);
      return {
        ...state,
        prep: {
          ...state.prep,
          localId: action.data.localId,
          name: action.data.name,
          businessId: action.data.businessId,
          description: action.data.description,
          thumbnail: action.data.thumbnail,
          startDate: moment(action.data.startDate).format('YYYY-MM-DD'),
          endDate: moment(action.data.endDate).format('YYYY-MM-DD'),
          fee: action.data.fee,
          privacyAllowClinicalTeamToInviteOthers:
          action.data.privacyAllowClinicalTeamToInviteOthers,
          privacyDoubleBlindStudy: action.data.privacyDoubleBlindStudy,
          messageEvents: sortMessageEvents([...messageEventsTemp]),
          welcomeSMS: action.data.welcomeSMS
        }
      };

    case actionTypes.UPDATE_PREP_FAILURE:
      return {
        ...state,
        hasServerError: true,
        serverErrorMessage: action.serverErrorMessage
      };

    case actionTypes.DELETE_PREP_FAILURE:
      return {
        ...state,
        hasServerError: true,
        serverErrorMessage: action.serverErrorMessage
      };

    case actionTypes.ADD_MESSAGE_EVENT:
      return {
        ...state,
        prep: {
          ...state.prep,
          messageEvents: [...state.prep.messageEvents, action.payload.newMessageEvent]
        }
      };
    case actionTypes.SET_BUILD_TREE_NODES:
      // eslint-disable-next-line no-case-declarations
      const { isBuild } = action.payload;
      return {
        ...state,
        prep: {
          ...state.prep,
          isReBuildNodes: isBuild
        },
      };

    case actionTypes.CHANGE_FIELD_PREP_INFO:
      return {
        ...state,
        prep: {
          ...state.prep,
          messageEvents: action.payload.messageEvents
        }
      };

    case actionTypes.GET_QUERY_TYPE_SUCCESS:
      return { ...state, queryTypes: action.data };

    case actionTypes.GET_QUERY_TYPE_FAILURE:
      return { ...state };

    case actionTypes.ADD_QUERY_TYPE:
      // eslint-disable-next-line no-case-declarations
      let children;
      // eslint-disable-next-line no-case-declarations
      const listChildren = [];
      if (action.value === '0') {
        children = [];
      } else if (action.value === '1') {
        children = [{ id: 1, name: 'YES' }, { id: 2, name: 'NO' }];
      } else if (action.value === '2') {
        children = [{ id: 1, name: '1' }, { id: 2, name: '2' }, { id: 3, name: '3' }, { id: 4, name: '4' }, {
          id: 5,
          name: '5'
        }];
        // children = [{ id: 1, name: '1' },
        // { id: 2, name: '2' },
        // { id: 3, name: '3' },
        // { id: 4, name: '4' },
        // { id: 5, name: '5' },
        // { id: 6, name: '6' },
        // { id: 7, name: '7' },
        // { id: 8, name: '8' },
        // { id: 9, name: '9' },
        // { id: 10, name: '10' }];
      } else if (action.value === '3') {
        children = [{ id: 1, name: 'A' }, { id: 2, name: 'B' }, { id: 3, name: 'C' }];
      }

      if (action.value === '0') {
        if (action.level === 1) {
          // eslint-disable-next-line no-param-reassign
          state.prep.messageEvents[action.MessageEventNumber].messageEventQuery = {
            ...state.prep.messageEvents[action.MessageEventNumber].messageEventQuery,
            queryTypeId: action.value,
            children: listChildren
          };
          return { ...state };
        }
        if (action.level === 2) {
          // eslint-disable-next-line no-param-reassign
          state.prep.messageEvents[action.MessageEventNumber]
            .messageEventQuery.children[action.childrenNumber] = {
              ...state.prep.messageEvents[action.MessageEventNumber]
                .messageEventQuery.children[action.childrenNumber],
              queryTypeId: action.value,
              children: listChildren
            };
          return { ...state };
        }
      } else {
        for (let i = 0; i < children.length; i++) {
          const queryType = {
            localId: '',
            messageEventId: '',
            messageEventQueryValue: children[i].name,
            queryTypeId: 0,
            content: '',
            query: '',
            level: action.level + 1,
            children: []
          };
          listChildren.push(queryType);
        }

        if (action.level === 1) {
          // eslint-disable-next-line no-param-reassign
          state.prep.messageEvents[action.MessageEventNumber].messageEventQuery = {
            ...state.prep.messageEvents[action.MessageEventNumber].messageEventQuery,
            queryTypeId: action.value,
            children: listChildren
          };
          return { ...state };
        }
        if (action.level === 2) {
          // eslint-disable-next-line no-param-reassign
          state.prep.messageEvents[action.MessageEventNumber]
            .messageEventQuery.children[action.childrenNumber] = {
              ...state.prep.messageEvents[action.MessageEventNumber]
                .messageEventQuery.children[action.childrenNumber],
              queryTypeId: action.value,
              children: listChildren
            };
          return { ...state };
        }
      }
      return state;

    case actionTypes.SHOW_UPLOAD_THUMBNAIL_MODAL: {
      return {
        ...state,
        prep: {
          ...state.prep,
          showUploadThumbnailModal: action.value
        }
      };
    }

    case actionTypes.FETCH_PREP_FORM_SUCCESS: {
      return {
        ...state,
        prepForm: action.prepForm
      };
    }

    case actionTypes.SHOW_CREATE_PREP_LOADING: {
      return {
        ...state,
        createPrepFormInfo: {
          ...state.createPrepFormInfo,
          loading: true
        }
      };
    }

    case actionTypes.CREATE_PREP_FORM_SUCCESS: {
      return {
        ...state,
        createPrepFormInfo: {
          showSuccessToast: true,
          hasError: false,
          message: action.message,
          loading: false
        }
      };
    }

    case actionTypes.CREATE_PREP_FORM_FAILURE: {
      return {
        ...state,
        createPrepFormInfo: {
          showSuccessToast: false,
          hasError: true,
          message: action.message,
          loading: false
        }
      };
    }

    case actionTypes.SET_LIST_PATIENT_CHANGED: {
      return {
        ...state,
        listPatientChanged: !state.listPatientChanged
      };
    }

    default:
      return { ...state };
  }
};

export default prepReducer;
