/* eslint-disable max-len */
import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  doctor: stringFormatter.formatUrl('/api/doctor'),
  doctorList: stringFormatter.formatUrl('/api/doctor/list'),
  saveDoctors: stringFormatter.formatUrl('/api/doctor/save-all'),
  createDoctor: stringFormatter.formatUrl('/api/doctor/create')
};
const doctorList = (keyword = '', page = 0, limit = 50, filter = '') => axiosInstance.get(`${endpoints.doctorList}?search=${keyword}&filter=${filter}&page=${page}&limit=${limit}`);
const saveDoctors = (objects) => axiosInstance.put(`${endpoints.saveDoctors}`, objects);
const deleteDoctor = (localId) => axiosInstance.delete(`${endpoints.doctor}/${localId}`);
const createDoctor = (object) => axiosInstance.post(`${endpoints.createDoctor}`, object);
export const doctorService = {
  doctorList,
  saveDoctors,
  deleteDoctor,
  createDoctor
};

export default {};
