import axios from 'axios';

export const authHeader = () => {
  const token = localStorage.getItem('token');

  if (token) {
    return { 'x-access-token': `${token}` };
  }
  return null;
};

const callApi = (method, url, payload = {}) => {
  const header = authHeader();
  // Add a request interceptor
  axios.interceptors.request.use(
    (config) =>
    // Do something before request is sent
    // eslint-disable-next-line implicit-arrow-linebreak
      config,
    (error) => {
    // Do something with request error
    // eslint-disable-next-line implicit-arrow-linebreak
      if (error.response && error.response.status === 401) {
        localStorage.clear();
      }
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) =>
    // Do something with response data
    // eslint-disable-next-line implicit-arrow-linebreak
      response,
    (error) =>
    // Do something with response error
    // eslint-disable-next-line implicit-arrow-linebreak
      Promise.reject(error)
  );

  const instance = axios.create({
    headers: header
  });

  switch (method) {
    case 'GET':
      return instance.get(url);
    case 'POST':
      return instance.post(url, payload);
    case 'PUT':
      return instance.put(url, payload);
    case 'DEL':
      return instance.delete(url);
    default:
      return instance.get(url);
  }
};

export const axiosInstance = {
  get: (url) => callApi('GET', url),
  put: (url, payload) => callApi('PUT', url, payload),
  post: (url, payload) => callApi('POST', url, payload),
  delete: (url) => callApi('DEL', url)
};
