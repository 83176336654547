export default {
  key: [
    'Field Inserts',
    'Surgery Date',
    'Surgery Time',
    'Facility Name',
    'Facility Address'
  ],
  value: [
    '<%=%>',
    '<%=surgery_date%>',
    '<%=surgery_time%>',
    '<%=facility_name%>',
    '<%=facility_address%>'
  ]
};
