/* eslint-disable */
import * as actionTypes from 'src/actions/actionTypes';

const initialState = {
  surgeryChanges: [],
  surgeries: [],
  hasServerError: false,
  serverMessage: '',
  loading: true,
  openAlert: false,
  refreshData: 0,
  cancelTemplates: []
};

const surgeryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_SURGERY_FIELD:
      const newList = state.surgeryChanges;
      const index = state.surgeryChanges.findIndex((i) => i.localId === action.payload.localId && i.patientId === action.payload.patientId);

      if (index !== -1) {
        newList[index] = { ...state.surgeryChanges[index], ...action.payload };
      } else {
        newList.push(action.payload);
      }
      return {
        ...state,
        surgeryChanges: newList
      };

    case actionTypes.RESET_SURGERY_FIELD:
      return {
        ...state,
        surgeryChanges: [],
        surgeries: [],
        refreshData: state.refreshData + 1
      };

    case actionTypes.SURGERY_PENDING:
      return {
        ...state,
        loading: true,
        openAlert: false,
      };

    case actionTypes.SAVE_SURGERY_SUCCESS:
      return {
        ...state,
        loading: false,
        surgeryChanges: [],
        openAlert: true,
        serverMessage: action.serverMessage,
        cancelTemplates: [],
      };

    case actionTypes.SAVE_SURGERY_FAILURE:
      return {
        ...state,
        loading: false,
        surgeryChanges: [],
        hasServerError: true,
        serverMessage: action.serverMessage,
        openAlert: true
      };

    case actionTypes.GET_ALL_SURGERY_SUCCESS:
      return {
        ...state,
        loading: false,
        surgeries: [...action.surgeries]
      };

    case actionTypes.GET_ALL_SURGERY_SUCCESS_APPEND:
      return {
        ...state,
        loading: false,
        surgeries: [...state.surgeries, ...action.surgeries]
      };

    case actionTypes.GET_ALL_SURGERY_FAILURE:
      return {
        ...state,
        loading: false,
        surgeries: []
      };
    case actionTypes.SURGERY_REMOVE_PATIENT_BY_ID:
      const { patientId } = action.payload;
      const updatedSurgeries = state.surgeries.map((surgery) => {
        if (surgery.patientId === patientId) {
          return { ...surgery, prepInstructions: null };
        }
        return surgery;
      });
      const cancelTemplatesUpdated = !state.cancelTemplates.includes(patientId) ? [...state.cancelTemplates, patientId] : state.cancelTemplates;
      return {
        ...state,
        surgeries: updatedSurgeries,
        cancelTemplates: cancelTemplatesUpdated,
      };

    default:
      return { ...state };
  }
};

export default surgeryReducer;
