import React from 'react';
import PropTypes from 'prop-types';
import { Alert, IconButton, Snackbar } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import './Toast.css';

function Toast({
  open, onClose, message, type
}) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={3000}
      onClose={onClose}
      open={open}
      className="alert-container"
    >
      <Alert
        className="alert-content"
        severity={type}
        action={(
          <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

Toast.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  message: PropTypes.string,
  type: PropTypes.string,
};

Toast.defaultProps = {
  open: true,
  onClose: () => {},
};

export default Toast;
