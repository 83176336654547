/* eslint-disable */
import { getCollaborations } from 'src/actions';
import * as actionTypes from './actionTypes';
import {
  commonService,
  patientService,
  prepService,
  userService
} from '../services';

export const getUser = () => (dispatch) => {
  userService.getUser().then(
    (response) => {
      const user = response.data;
      dispatch({ type: actionTypes.GET_USER_SUCCESS, user });
    },
    () => {
      dispatch({ type: actionTypes.GET_ACCOUNT_FAILURE });
    }
  );
};

export const loginUrlToken = (token, history) => (dispatch) => {
  dispatch({
    type: actionTypes.SESSION_LOGIN
  });

  localStorage.setItem('token', token);
  dispatch(getUser());
  const message = 'You successfully logged in.';
  dispatch({ type: actionTypes.SESSION_LOGIN_SUCCESS, message });
  dispatch(getCollaborations());
  // get first preps
  prepService.getCollaborations().then(
    (res) => {
      const dataPreps = res.data;
      if (dataPreps.length > 0) {
        const selectFirst = dataPreps[0].localId;
        history(`/preps/patients/${selectFirst}`);
        localStorage.setItem('selectedPrep', selectFirst);
      } else {
        history('/preps/create');
      }
    },
    () => {
      history('/');
    }
  );
};

// For website
export const authLogin = (email, password, history) => async (dispatch) => {
  dispatch({
    type: actionTypes.SESSION_LOGIN
  });

  try {
    const response = await userService.authLogin(email, password);
    if (response.data.data.verified === false) {
      const parseObject = JSON.parse(response.config.data);
      sessionStorage.setItem('email', parseObject.email);
      history('/auth/verify');
    }
  } catch (error) {
    const { success, message } = error.response.data;
    switch (message) {
      case 'Incorrect password, this is your 1st attempt.':
        dispatch({
          type: actionTypes.SESSION_ACCOUNT_LOCKOUT_1ST,
          success,
          message
        });
        break;
      case 'Incorrect password, this is your 2nd attempt.':
        dispatch({
          type: actionTypes.SESSION_ACCOUNT_LOCKOUT_2ND,
          success,
          message
        });
        break;
      case 'Your account has been locked for 30 minutes.':
        dispatch({
          type: actionTypes.SESSION_ACCOUNT_LOCKOUT_10M,
          success,
          message
        });
        break;
      case 'Account lockout!':
        dispatch({
          type: actionTypes.SESSION_ACCOUNT_LOCKOUT,
          success,
          message
        });
        break;
      default:
        break;
    }
  }
};

// For react native app
export const login = (email, password, history) => async (dispatch) => {
  dispatch({
    type: actionTypes.SESSION_LOGIN
  });
  try {
    const response = await userService.login(email, password);
    if (response.data.data.verified === false) {
      const parseObject = JSON.parse(response.config.data);
      sessionStorage.setItem('email', parseObject.email);
      history('/auth/verify');
    }
  } catch (error) {
    const { success, message } = error.response.data;
    switch (message) {
      case 'Incorrect password, this is your 1st attempt.':
        dispatch({
          type: actionTypes.SESSION_ACCOUNT_LOCKOUT_1ST,
          success,
          message
        });
        break;
      case 'Incorrect password, this is your 2nd attempt.':
        dispatch({
          type: actionTypes.SESSION_ACCOUNT_LOCKOUT_2ND,
          success,
          message
        });
        break;
      case 'Your account has been locked for 30 minutes.':
        dispatch({
          type: actionTypes.SESSION_ACCOUNT_LOCKOUT_10M,
          success,
          message
        });
        break;
      case 'Account lockout!':
        dispatch({
          type: actionTypes.SESSION_ACCOUNT_LOCKOUT,
          success,
          message
        });
        break;
      default:
        break;
    }
  }
};

export const twoFactorAuth = (email, otp, history) => (dispatch) => {
  dispatch({
    type: actionTypes.SESSION_TWO_FACTOR_AUTH
  });

  userService.twoFactorAuth(email, otp).then(
    (response) => {
      if (response.data && response.data.success && response.data.data.token) {
        localStorage.setItem('token', response.data.data.token);
        dispatch(getUser());
        const { success, message } = response.data;
        dispatch({ type: actionTypes.SESSION_LOGIN_SUCCESS, success, message });
        dispatch(getCollaborations());
        // get first preps
        prepService.getCollaborations().then(
          (res) => {
            const dataPreps = res.data;
            if (dataPreps.length > 0) {
              const selectFirst = dataPreps[0].localId;
              history('/preps/patients/a25ca1bd-7b62-42ed-9f3b-9e4dc35d9d71');
              localStorage.setItem('selectedPrep', selectFirst);
            } else {
              history('/preps/patients/a25ca1bd-7b62-42ed-9f3b-9e4dc35d9d71');
            }
          },
          () => {
            history('/');
          }
        );
      }
    },
    (error) => {
      const { success, message, data } = error.response.data;
      switch (data.errorCode) {
        case 0:
          dispatch({
            type: actionTypes.SESSION_OTP_DOES_NOT_MATCH,
            success,
            message
          });
          break;
        case 1:
          dispatch({
            type: actionTypes.SESSION_OTP_HAS_BEEN_USED,
            success,
            message
          });
          break;
        case 2:
          dispatch({
            type: actionTypes.SESSION_OTP_HAS_EXPIRED,
            success,
            message
          });
          break;
        case 3:
          dispatch({ type: actionTypes.SESSION_OTP_SPAM, success, message });
          break;
        default:
          break;
      }
    }
  );
};

export const register =
  (
    isAdministrator,
    businessName,
    title,
    location,
    affiliation,
    firstName,
    lastName,
    email,
    mobileNumber,
    password,
    // eslint-disable-next-line no-unused-vars
    history,
    userType
  ) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SESSION_REGISTER
    });

    userService
      .register(
        isAdministrator,
        businessName,
        title,
        location,
        affiliation,
        firstName,
        lastName,
        email,
        mobileNumber,
        password,
        userType
      )
      .then(
        (response) => {
          if (
            response.data &&
            response.data.success &&
            response.data.data.token
          ) {
            const { success, message } = response.data;
            dispatch({
              type: actionTypes.SESSION_REGISTER_SUCCESS,
              success,
              message
            });
            alert('Done');
          }
        },
        (error) => {
          const { success, message } = error.response.data;
          dispatch({
            type: actionTypes.SESSION_REGISTER_FAILURE,
            success,
            message
          });
        }
      );
  };

export const update =
  (
    localId,
    businessName,
    title,
    location,
    affiliation,
    firstName,
    lastName,
    email,
    mobileNumber,
    // eslint-disable-next-line no-unused-vars
    history,
    userType,
    locked
  ) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SESSION_UPDATE
    });
    let loginAttempts = 0;
    if (locked) {
      loginAttempts = 4;
    }
    userService
      .update(
        localId,
        businessName,
        title,
        location,
        affiliation,
        firstName,
        lastName,
        email,
        mobileNumber,
        userType,
        loginAttempts
      )
      .then(
        (response) => {
          if (response.data && response.status === 200) {
            const { success, message } = response.data;
            dispatch({
              type: actionTypes.SESSION_UPDATE_SUCCESS,
              success,
              message
            });
            alert('Update successful!');
            window.location.reload();
          }
        },
        (error) => {
          const { success, message } = error.response.data;
          dispatch({
            type: actionTypes.SESSION_UPDATE_FAILURE,
            success,
            message
          });
        }
      );
  };

export const patientLogin = (id) => (dispatch) => {
  dispatch({ type: actionTypes.SET_PATIENT_LOADING, value: true });
  patientService.getPatientById(id).then(
    (response) => {
      const patient = response.data;
      dispatch({ type: actionTypes.SESSION_PATIENT_LOGIN, patient });
      dispatch({ type: actionTypes.SET_PATIENT_LOADING, value: false });
    },
    (error) => {
      console.log(error);
      dispatch({ type: actionTypes.SET_PATIENT_LOADING, value: false });
    }
  );
};

export const requestResetPassword = (email, mobileNumber) => (dispatch) => {
  userService.requestResetPassword(email, mobileNumber).then(
    () => {
      dispatch({
        type: actionTypes.REQUEST_RESET_PASSWORD_SUCCESS,
        resetPasswordEmail: email
      });
    },
    (error) => {
      const { message } = error.response.data;
      dispatch({
        type: actionTypes.REQUEST_RESET_PASSWORD_FAILURE,
        message,
        resetPasswordEmail: email
      });
    }
  );
};

export const resetPassword = (id, password, token, history) => (dispatch) => {
  userService.resetPassword(id, password, token).then(
    () => {
      history('/auth/login');
      dispatch({
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        message: ''
      });
    },
    (error) => {
      const { message } = error.response.data;
      dispatch({
        type: actionTypes.RESET_PASSWORD_FAILURE,
        message
      });
    }
  );
};

export const showResetMessageFailed = (message) => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_PASSWORD_FAILURE,
    isResetPassword: false,
    messageResetPassword: message
  });
};

export const logout = (history) => (dispatch) => {
  localStorage.clear();
  history('/auth/login');

  dispatch({ type: actionTypes.SESSION_LOGOUT });
};

export const fetchBusiness = (businessId) => (dispatch) => {
  commonService.getBusiness(businessId).then((response) => {
    const business = response.data;
    dispatch({ type: actionTypes.FETCH_BUSINESS_SUCCESS, business });
  });
};

export const verifyPatientFail = (dispatch) => {
  dispatch({ type: actionTypes.VERIFY_PATIENT_FAILURE });
};

export const verifyPatientFail2 = (dispatch) => {
  dispatch({ type: actionTypes.VERIFY_PATIENT_FAILURE_2 });
};

export const sendVerificationEmail = (patientId, patientEmail) =>
  patientService.sendVerificationEmail(patientId, patientEmail);

export const sendOverdueEmail = (appointmentId) =>
  patientService.sendOverdueEmail(appointmentId);

export const setIsVerify = (value) => (dispatch) => {
  dispatch({ type: actionTypes.SET_IS_VERIFY_FOR_PATIENT, value });
};

export const deleteUserById = (userId) => (dispatch) => {
  userService.deleteUserById(userId).then(
    (response) => {
      const { data } = response;
      dispatch({ type: actionTypes.SESSION_DELETE_USER_BY_ID_SUCCESS, data });
      alert('Done');
      window.location.reload();
    },
    () => {
      dispatch({ type: actionTypes.SESSION_DELETE_USER_BY_ID_FAILURE });
    }
  );
};

export const adminUpdatePasswordForUser =
  (localId, newPassword) => (dispatch) => {
    userService.updatePasswordForUserByAdmin(localId, newPassword).then(
      (response) => {
        const { data } = response;
        dispatch({
          type: actionTypes.SESSION_PASSWORD_FOR_USER_BY_ADMIN_SUCCESS,
          data
        });
        alert('Update password success');
      },
      () => {
        dispatch({
          type: actionTypes.SESSION_PASSWORD_FOR_USER_BY_ADMIN_FAILURE
        });
      }
    );
  };
