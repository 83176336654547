/* eslint-disable max-len */
import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  saveAppoinments: stringFormatter.formatUrl('/api/appointment/save-all')
};
const saveAppoinments = (appointments) => axiosInstance.post(`${endpoints.saveAppoinments}`, appointments);

export const appointmentService = {
  saveAppoinments
};

export default {};
