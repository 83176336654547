import React from 'react';
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import events from 'src/constant/events';
import configuration from 'src/configuration';
import {
  socketConnect
} from 'src/actions';

// eslint-disable-next-line import/no-mutable-exports
let socket;

function SocketHolder() {
  const dispatch = useDispatch();

  if (configuration.env === 'development') {
    socket = io(`${configuration.messagingBaseUrl}`);
  } else {
    socket = io.connect();
  }

  socket.on(events.socket.CONNECT, () => {
    dispatch(socketConnect());
  });

  socket.on(events.socket.ERROR, () => {
    dispatch(socketConnect());
  });

  return <div />;
}

export { SocketHolder, socket };
