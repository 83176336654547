import * as actionTypes from './actionTypes';

export const socketConnect = () => (dispatch) => {
  dispatch({ type: actionTypes.SOCKET_CONNECT, connected: true });
};

export const getMessageByPatient = (selectedPatientId) => (dispatch) => {
  dispatch({ type: actionTypes.GET_PATIENT_MESSAGE_SUCCESS, selectedPatientId });
};

export const changeTextMessage = (value) => (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_TEXT_MESSAGE, value });
};

export const sendMessage = (messageContent, user, patientId, collaborationId) => (dispatch) => {
  dispatch({
    type: actionTypes.SEND_MESSAGE, messageContent, user, patientId, collaborationId
  });
};

export const sendMessageObject = (message) => (dispatch) => {
  dispatch({
    type: actionTypes.SEND_MESSAGE_OBJECT, message
  });
};
