import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Popover,
  CardHeader,
  Divider
} from '@mui/material';
import NotificationList from './NotificationList';
import style from './index.style';

const useStyles = makeStyles(style);

function NotificationsPopover({ notifications, anchorEl, ...rest }) {
  const classes = useStyles();

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <div className={classes.root}>
        <CardHeader title="Notifications" />
        <Divider />
        {notifications.length > 0 ? (
          <NotificationList notifications={notifications} />
        ) : (
          null
          // <Placeholder />
        )}
      </div>
    </Popover>
  );
}

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default NotificationsPopover;
