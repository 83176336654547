/* eslint-disable */
import * as actionTypes from 'src/actions/actionTypes';

const initialState = {
  reviews: [],
  hasServerError: false,
  serverMessage: '',
  loading: true,
  openAlert: false,
  refreshData: 0
};

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_REVIEW_FIELD:
      return {
        ...state,
        reviews: [],
        refreshData: state.refreshData + 1
      };
    case actionTypes.GET_ALL_REVIEW_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        reviews: [...action.reviews]
      };

    case actionTypes.GET_ALL_REVIEW_LIST_SUCCESS_APPEND:
      return {
        ...state,
        loading: false,
        reviews: [...state.reviews, ...action.reviews]
      };

    case actionTypes.GET_ALL_REVIEW_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        reviews: []
      };

    default:
      return { ...state };
  }
};

export default reviewReducer;
