const GetDescription = (description) => {
  const obj = JSON.parse(description);

  const { text } = obj.blocks[0];

  if (text) {
    if (text.length > 255) {
      return `${obj.blocks[0].text.substring(0, 255)}...`;
    }

    return text;
  }

  return '';
};

const GetFilename = (filePath) => {
  if (filePath) {
    const indices = [];
    for (let i = 0; i < filePath.length; i++) {
      if (filePath[i] === '/') indices.push(i);
    }

    const lastCharacter = indices[indices.length - 1];
    const filename = [];
    for (let i = lastCharacter + 1; i < filePath.length; i++) {
      filename.push(filePath[i]);
    }
    return filename.join('');
  }

  return '';
};

module.exports = {
  GetDescription,
  GetFilename
};
