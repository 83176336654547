const map24h = new Map();
map24h.set('12:00 AM', '00:00');
map24h.set('12:15 AM', '00:15');
map24h.set('12:30 AM', '00:30');
map24h.set('12:45 AM', '00:45');
map24h.set('1:00 AM', '01:00');
map24h.set('1:15 AM', '01:15');
map24h.set('1:30 AM', '01:30');
map24h.set('1:45 AM', '01:45');
map24h.set('2:00 AM', '02:00');
map24h.set('2:15 AM', '02:15');
map24h.set('2:30 AM', '02:30');
map24h.set('2:45 AM', '02:45');
map24h.set('3:00 AM', '03:00');
map24h.set('3:15 AM', '03:15');
map24h.set('3:30 AM', '03:30');
map24h.set('3:45 AM', '03:45');
map24h.set('4:00 AM', '04:00');
map24h.set('4:15 AM', '04:15');
map24h.set('4:30 AM', '04:30');
map24h.set('4:45 AM', '04:45');
map24h.set('5:00 AM', '05:00');
map24h.set('5:15 AM', '05:15');
map24h.set('5:30 AM', '05:30');
map24h.set('5:45 AM', '05:45');
map24h.set('6:00 AM', '06:00');
map24h.set('6:15 AM', '06:15');
map24h.set('6:30 AM', '06:30');
map24h.set('6:45 AM', '06:45');
map24h.set('7:00 AM', '07:00');
map24h.set('7:15 AM', '07:15');
map24h.set('7:30 AM', '07:30');
map24h.set('7:45 AM', '07:45');
map24h.set('8:00 AM', '08:00');
map24h.set('8:15 AM', '08:15');
map24h.set('8:30 AM', '08:30');
map24h.set('8:45 AM', '08:45');
map24h.set('9:00 AM', '09:00');
map24h.set('9:15 AM', '09:15');
map24h.set('9:30 AM', '09:30');
map24h.set('9:45 AM', '09:45');
map24h.set('10:00 AM', '10:00');
map24h.set('10:15 AM', '10:15');
map24h.set('10:30 AM', '10:30');
map24h.set('10:45 AM', '10:45');
map24h.set('11:00 AM', '11:00');
map24h.set('11:15 AM', '11:15');
map24h.set('11:30 AM', '11:30');
map24h.set('11:45 AM', '11:45');
map24h.set('12:00 PM', '12:00');
map24h.set('12:15 PM', '12:15');
map24h.set('12:30 PM', '12:30');
map24h.set('12:45 PM', '12:45');
map24h.set('1:00 PM', '13:00');
map24h.set('1:15 PM', '13:15');
map24h.set('1:30 PM', '13:30');
map24h.set('1:45 PM', '13:45');
map24h.set('2:00 PM', '14:00');
map24h.set('2:15 PM', '14:15');
map24h.set('2:30 PM', '14:30');
map24h.set('2:45 PM', '14:45');
map24h.set('3:00 PM', '15:00');
map24h.set('3:15 PM', '15:15');
map24h.set('3:30 PM', '15:30');
map24h.set('3:45 PM', '15:45');
map24h.set('4:00 PM', '16:00');
map24h.set('4:15 PM', '16:15');
map24h.set('4:30 PM', '16:30');
map24h.set('4:45 PM', '16:45');
map24h.set('5:00 PM', '17:00');
map24h.set('5:15 PM', '17:15');
map24h.set('5:30 PM', '17:30');
map24h.set('5:45 PM', '17:45');
map24h.set('6:00 PM', '18:00');
map24h.set('6:15 PM', '18:15');
map24h.set('6:30 PM', '18:30');
map24h.set('6:45 PM', '18:45');
map24h.set('7:00 PM', '19:00');
map24h.set('7:15 PM', '19:15');
map24h.set('7:30 PM', '19:30');
map24h.set('7:45 PM', '19:45');
map24h.set('8:00 PM', '20:00');
map24h.set('8:15 PM', '20:15');
map24h.set('8:30 PM', '20:30');
map24h.set('8:45 PM', '20:45');
map24h.set('9:00 PM', '21:00');
map24h.set('9:15 PM', '21:15');
map24h.set('9:30 PM', '21:30');
map24h.set('9:45 PM', '21:45');
map24h.set('10:00 PM', '22:00');
map24h.set('10:15 PM', '22:15');
map24h.set('10:30 PM', '22:30');
map24h.set('10:45 PM', '22:45');
map24h.set('11:00 PM', '23:00');
map24h.set('11:15 PM', '23:15');
map24h.set('11:30 PM', '23:30');
map24h.set('11:45 PM', '23:45');

export default map24h;
