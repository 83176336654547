import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { logout } from 'src/actions';
// Example of user roles: ['GUEST', 'USER', 'ADMIN'];

function AuthGuard({ roles, children }) {
  const session = useSelector((state) => state.session);
  const history = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!session.loggedIn || !session.user || !token) {
      dispatch(logout(history));
    }
  }, [dispatch, history, roles, session.loggedIn, session.user, token]);

  return <div>{children}</div>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array.isRequired
};

export default AuthGuard;
