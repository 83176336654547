export default {
  REGISTER: 'REGISTER',
  CONNECTED: 'CONNECTED',
  JOIN: 'JOIN',
  LEAVE: 'LEAVE',
  MESSAGE: 'MESSAGE',
  AVAILABLE_USERS: 'AVAILABLE_USERS',
  DISCONNECT: 'DISCONNECT',
  ERROR: 'ERROR',
  TYPING: 'TYPING',
  MESSAGE_EVENT_ANSWER: 'MESSAGE_EVENT_ANSWER'
};
