import { accountServices } from 'src/services';
import * as actionTypes from './actionTypes';
import { logout } from './sessionActions';

export const getAccount = () => (dispatch) => {
  accountServices.getAccount()
    .then((response) => {
      const account = response.data;
      dispatch({ type: actionTypes.GET_ACCOUNT_SUCCESS, account });
    }, () => {
      dispatch({ type: actionTypes.GET_ACCOUNT_FAILURE });
    });
};

export const changeTextProfile = (isChangeProfile, name, value) => (dispatch) => {
  dispatch({
    type: actionTypes.CHANGE_TEXT_PROFILE, isChangeProfile, name, value
  });
};

export const turnOnModalConfirm = () => (dispatch) => {
  dispatch({ type: actionTypes.TURN_ON_MODAL_CONFIRM });
};

export const turnOffModalConfirm = (history) => (dispatch) => {
  dispatch(logout(history));
  dispatch({ type: actionTypes.TURN_OFF_MODAL_CONFIRM });
};

export const saveChangeProfile = (isChangeProfile, changeValue) => (dispatch) => {
  if (isChangeProfile) {
    accountServices.saveChange(isChangeProfile, changeValue)
      .then((response) => {
        const profile = response.data;
        dispatch({ type: actionTypes.CLICK_SAVE_CHANGE_PROFILE_SUCCESS, profile });
        dispatch({ type: actionTypes.UPDATE_PROFILE_SESSION, profile });
      }, (error) => {
        const { message } = error.response.data;
        dispatch({
          type: actionTypes.CHANGE_PROFILE_FAILURE,
          message
        });
        dispatch({ type: actionTypes.CLICK_SAVE_CHANGE_PROFILE_FAILURE });
      });
  } else {
    accountServices.saveChange(isChangeProfile, changeValue)
      .then(() => {
        dispatch({ type: actionTypes.TURN_ON_MODAL_CONFIRM });
      }, (error) => {
        const { message } = error.response.data;
        dispatch({
          type: actionTypes.CHANGE_PROFILE_FAILURE,
          message
        });
      });
  }
};
