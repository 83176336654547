/* eslint-disable */
import * as actionTypes from 'src/actions/actionTypes';

const initialState = {
  appointmentChanges: [],
  appointments: [],
  hasServerError: false,
  serverMessage: '',
  loading: true,
  openAlert: false,
  refreshData: 0
};

const appointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_APPOINTMENT_FIELD:
      const newList = state.appointmentChanges;
      const index = state.appointmentChanges.findIndex((i) => i.localId === action.payload.localId && i.patientId === action.payload.patientId);

      if (index !== -1) {
        newList[index] = { ...state.appointmentChanges[index], ...action.payload };
      } else {
        newList.push(action.payload);
      }
      return {
        ...state,
        appointmentChanges: newList
      };

    case actionTypes.RESET_APPOINTMENT_FIELD:
      return {
        ...state,
        appointmentChanges: [],
        appointments: [],
        refreshData: state.refreshData + 1
      };

    case actionTypes.APPOINTMENT_PENDING:
      return {
        ...state,
        loading: true,
        openAlert: false,
      };

    case actionTypes.SAVE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointmentChanges: [],
        openAlert: true,
        serverMessage: action.serverMessage,
      };

    case actionTypes.SAVE_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        appointmentChanges: [],
        hasServerError: true,
        serverMessage: action.serverMessage,
        openAlert: true
      };

    case actionTypes.GET_ALL_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointments: [...action.appointments]
      };

    case actionTypes.GET_ALL_APPOINTMENT_SUCCESS_APPEND:
      return {
        ...state,
        loading: false,
        appointments: [...state.appointments, ...action.appointments]
      };

    case actionTypes.GET_ALL_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        appointments: []
      };

    default:
      return { ...state };
  }
};

export default appointmentReducer;
