/* eslint-disable */
import * as actionTypes from 'src/actions/actionTypes';

const initialState = JSON.parse(localStorage.getItem('session')) || {
  loggedIn: false,
  isLogin: true,
  messageLogin: '',
  isRegister: true,
  messageRegister: '',
  selectedPracticeName: '',
  user: {
    localId: '',
    businessId: '',
    isDoctor: false,
    firstName: '',
    lastName: '',
    email: '',
    avatar: '/images/avatars/avatar_11.png',
    mobileNumber: '',
    role: '',
    userType: 0,
    location: '',
    affiliation: 0,
  },
  resetPasswordEmail: '',
  isRequestResetPassword: false,
  isRequestResetPasswordFailed: false,
  messageRequestResetPasswordFailed: '',
  isResetPasswordFailed: false,
  messageResetPasswordFailed: '',
  resetOTPEmail: '',
  isRequestResendOTP: false,
  isRequestResendOTPFailed: false,
  messageRequestResendOTPFailed: '',
  isResetOTPFailed: false,
  messageResendOTPFailed: '',
  fetchedBusiness: {},
  locked: false,
  verifyFailed: false,
  loading: true,
  isVerifyOTPFailed: false,
  messageVerifyOTP: '',
  isDisableVerifyOTP: false,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...state
      };
    }

    case actionTypes.SESSION_LOGIN_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        isLogin: true,
        messageLogin: action.message
      };
    }

    case actionTypes.SESSION_LOGIN_FAILURE: {
      return {
        ...state,
        loggedIn: false,
        isLogin: action.success,
        messageLogin: 'Login failed!',
        user: {
          role: 'GUEST'
        }
      };
    }

    case actionTypes.SESSION_ACCOUNT_LOCKOUT: {
      return {
        ...state,
        loggedIn: false,
        isLogin: action.success,
        messageLogin: action.message,
        user: {
          role: 'GUEST'
        }
      };
    }

    case actionTypes.SESSION_ACCOUNT_LOCKOUT_10M: {
      return {
        ...state,
        loggedIn: false,
        isLogin: action.success,
        messageLogin: action.message,
        user: {
          role: 'GUEST'
        }
      };
    }
    case actionTypes.SESSION_ACCOUNT_LOCKOUT_1ST: {
      return {
        ...state,
        loggedIn: false,
        isLogin: action.success,
        messageLogin: action.message,
        user: {
          role: 'GUEST'
        }
      };
    }
    case actionTypes.SESSION_ACCOUNT_LOCKOUT_2ND: {
      return {
        ...state,
        loggedIn: false,
        isLogin: action.success,
        messageLogin: action.message,
        user: {
          role: 'GUEST'
        }
      };
    }
    case actionTypes.SESSION_TWO_FACTOR_AUTH: {
      return {
        ...state
      };
    }
    case actionTypes.SESSION_OTP_DOES_NOT_MATCH: {
      return {
        ...state,
        isVerifyOTPFailed: false,
        messageVerifyOTP: action.message,
        user: {
          role: 'GUEST'
        }
      };
    }
    case actionTypes.SESSION_OTP_HAS_BEEN_USED: {
      return {
        ...state,
        isVerifyOTPFailed: false,
        messageVerifyOTP: action.message,
        user: {
          role: 'GUEST'
        }
      };
    }
    case actionTypes.SESSION_OTP_HAS_EXPIRED: {
      return {
        ...state,
        isVerifyOTPFailed: false,
        messageVerifyOTP: action.message,
        user: {
          role: 'GUEST'
        }
      };
    }
    case actionTypes.SESSION_OTP_SPAM: {
      return {
        ...state,
        isVerifyOTPFailed: false,
        isDisableVerifyOTP: true,
        messageVerifyOTP: action.message,
        user: {
          role: 'GUEST'
        }
      };
    }
    case actionTypes.GET_USER_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const returnState = {
        ...state,
        localId: action.user.localId,
        businessId: action.user.businessId,
        isDoctor: action.user.isDoctor,
        firstName: action.user.firstName,
        lastName: action.user.lastName,
        avatar: action.user.avatar,
        email: action.user.email,
        mobileNumber: action.user.mobileNumber,
        role: action.user.role,
        userType: action.user.userType,
        user: {
          localId: action.user.localId,
          businessId: action.user.businessId,
          isDoctor: action.user.isDoctor,
          firstName: action.user.firstName,
          lastName: action.user.lastName,
          email: action.user.email,
          avatar: action.user.avatar,
          mobileNumber: action.user.mobileNumber,
          role: action.user.role,
          userType: action.user.userType,
          location: action.user.location,
          affiliation: action.user.affiliation
        }
      };
      localStorage.setItem('session', JSON.stringify(returnState));
      return returnState;

    case actionTypes.UPDATE_PROFILE_SESSION:
      // eslint-disable-next-line no-case-declarations
      const returnStateProfile = {
        ...state,
        firstName: action.profile.firstName,
        lastName: action.profile.lastName,
        avatar: action.profile.avatar,
        email: action.profile.email,
        mobileNumber: action.profile.mobileNumber
      };

      localStorage.setItem('session', JSON.stringify(returnStateProfile));
      return returnStateProfile;

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: initialState.user
      };
    }

    case actionTypes.SESSION_REGISTER_SUCCESS: {
      return {
        ...state,
        isRegister: action.success,
        messageRegister: action.message
      };
    }

    case actionTypes.SESSION_REGISTER_FAILURE: {
      return {
        ...state,
        isRegister: action.success,
        messageRegister: action.message
      };
    }

    case actionTypes.SELECTED_PRACTICE_NAME:
      return {
        ...state,
        selectedPracticeName: action.selectedPracticeName
      };

    case actionTypes.SESSION_PATIENT_LOGIN:
      return {
        ...state,
        localId: action.patient.localId,
        businessId: action.patient.businessId,
        procedureDoctorId: action.patient.procedureDoctorId,
        telehealthDoctorId: action.patient.telehealthDoctorId,
        collaborationId: action.patient.collaborationId,
        isDoctor: action.patient.isDoctor,
        firstName: action.patient.firstName,
        lastName: action.patient.lastName,
        email: action.patient.email,
        mobileNumber: action.patient.mobileNumber,
        business: action.patient.business,
        businessLocation: action.patient.businessLocation,
        provider: action.patient.provider,
        role: 'PATIENT',
        isVerified: action.patient.isVerified,
        userType: action.patient.userType
      };

    case actionTypes.REQUEST_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordEmail: action.resetPasswordEmail,
        isRequestResetPassword: true,
        isRequestResetPasswordFailed: false,
        messageRequestResetPasswordFailed: ''
      };

    case actionTypes.REQUEST_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordEmail: action.resetPasswordEmail,
        isRequestResetPasswordFailed: true,
        messageRequestResetPasswordFailed: action.message
      };

    case actionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isResetPasswordFailed: true,
        messageResetPasswordFailed: action.message
      };

    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPasswordFailed: false,
        messageResetPasswordFailed: ''
      };

    case actionTypes.FETCH_BUSINESS_SUCCESS:
      return {
        ...state,
        fetchedBusiness: action.business
      };

    case actionTypes.VERIFY_PATIENT_FAILURE:
      return {
        ...state,
        locked: true
      };

    case actionTypes.VERIFY_PATIENT_FAILURE_2:
      return {
        ...state,
        verifyFailed: true
      };

    case actionTypes.SET_IS_VERIFY_FOR_PATIENT:
      return {
        ...state,
        isVerified: action.value
      };

    case actionTypes.SET_PATIENT_LOADING:
      return {
        ...state,
        loading: action.value
      };

    default: {
      return state;
    }
  }
};

export default sessionReducer;
