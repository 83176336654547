import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  getNotificationsByPrep: stringFormatter.formatUrl('/api/notifications?businessId='),
  notifications: stringFormatter.formatUrl('/api/notifications')
};

const getNotificationsByBusiness = (businessId) => axiosInstance.get(`${endpoints.getNotificationsByPrep}${businessId}`);

const updateNotification = (notification) => axiosInstance.put(`${endpoints.notifications}`, notification);

export const notificationsService = {
  getNotificationsByBusiness,
  updateNotification
};

export default {};
