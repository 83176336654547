/* eslint-disable max-len */
import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  saveSurgery: stringFormatter.formatUrl('/api/surgery/save-all'),
  getSurgeryByPrep: stringFormatter.formatUrl('/api/surgery/'),
  addReview: stringFormatter.formatUrl('/api/surgery/'),
  reviewTypeByProvider: stringFormatter.formatUrl('/api/surgery/provider/'),
  cancelSurgeryTemplate: stringFormatter.formatUrl('/api/surgery/templates'),
};
const saveSurgery = (surgeries) => axiosInstance.post(`${endpoints.saveSurgery}`, surgeries);
const getSurgeryByPrep = (prepId, page = 0, limit = 50, keyword = '', filter = '') => axiosInstance.get(`${endpoints.getSurgeryByPrep}${prepId}?page=${page}&limit=${limit}&keyword=${keyword}&filter=${filter}`);
const createReview = (review) => axiosInstance.post(endpoints.addReview, review);
const reviewTypeByProvider = (providerId) => axiosInstance.get(`${endpoints.reviewTypeByProvider}${providerId}`);
const cancelSurgeryTemplate = (patientId) => axiosInstance.delete(`${endpoints.cancelSurgeryTemplate}/${patientId}`);

export const surgeryService = {
  saveSurgery,
  getSurgeryByPrep,
  createReview,
  reviewTypeByProvider,
  cancelSurgeryTemplate,

};

export default {};
