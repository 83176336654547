/* eslint-disable */

import moment from 'moment';

import * as actionTypes from 'src/actions/actionTypes';

const initialState = {
  conversations: [],
  patientMessage: {
    id: '',
    messages: [],
  },
  messageContent: '',
  providers: [],
  socketConnected: false
};

const buildMessages = (state, action) => {
  const { conversations } = state;
  const { payload } = action;
  const {
    id,
    patientId,
    collaborationId,
    sender,
    content,
    contentType,
    messageDateTime,
    answered,
    answeredContent,
    patient
  } = payload;
  const authUser = sender ? sender.authUser : false;

  const message = {
    id,
    patientId,
    collaborationId,
    sender: {
      id: !sender ? null : sender.id, // get from users/me
      authUser,
      // eslint-disable-next-line max-len,no-nested-ternary
      name: !sender ? null : sender.sender_name ? sender.sender_name : sender.name, // get from users/me
      avatar: !sender ? null : sender.avatar, // get from users/me
      procedureTime: sender ? sender.procedureTime : '',
      dateOfBirth: sender ? sender.dateOfBirth : '',
      mobilePhone: sender ? sender.mobilePhone : '',
      email: sender ? sender.email : '',
      streetAddress: sender ? sender.streetAddress : ''
    },
    patient,
    answered,
    answeredContent,
    content: contentType === 'QUERY' || contentType === 'ANSWER' ? JSON.parse(content) : content,
    contentType,
    createdAt: messageDateTime
  };

  if (!conversations.some((c) => c.id === payload.patientId)) {
    conversations.push({
      id: payload.patientId,
      messages: [
        message
      ],
      unread: 0,
      created_at: moment().subtract(1, 'minute')
    });
  } else {
    const foundConversation = state.conversations.find((c) => c.id === payload.patientId);

    const foundMessageIndex = foundConversation.messages.findIndex(((m) => m.id === message.id));

    if (foundMessageIndex >= 0) {
      foundConversation.messages[foundMessageIndex] = message;
    } else {
      foundConversation.messages = [
        ...foundConversation.messages,
        message
      ];
    }

    const foundConversationIndex = conversations.findIndex((c) => c.id === foundConversation.id);

    conversations[foundConversationIndex] = foundConversation;
  }

  const sortedMessagesConversations = conversations.map((m) => ({
    ...m,
    messages: m.messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  }));

  return sortedMessagesConversations;
};

const getAnsweredMessageOnConversations = (state, action) => {
  const { conversations } = state;

  if (!action.content
    || !action.content.messageLocalId) {
    return conversations;
  }

  const { messageLocalId, answerValue } = action.content;

  const foundConversation = state.conversations
    .find((c) => c.messages.some((m) => m.id === messageLocalId));

  const foundMessageIndex = foundConversation.messages.findIndex(((m) => m.id === messageLocalId));

  if (foundMessageIndex >= 0) {
    foundConversation.messages[foundMessageIndex] = {
      ...foundConversation.messages[foundMessageIndex],
      answered: true,
      answeredContent: answerValue
    };

    const foundConversationIndex = conversations.findIndex((c) => c.id === foundConversation.id);
    conversations[foundConversationIndex] = foundConversation;
  }

  return conversations;
};

const getMessageUpdatedOnConversations = (state, action) => {
  const { conversations } = state;

  if (!action.payload
    || !action.payload.local_id) {
    return conversations;
  }

  // const { local_id, business_id, patient_id, answered, answered_content  } = action.payload;
  const { local_id, answered, answered_content } = action.payload;

  const foundConversation = state.conversations
    .find((c) => c.messages.some((m) => m.id === local_id));

  const foundMessageIndex = foundConversation.messages.findIndex(((m) => m.id === local_id));

  if (foundMessageIndex >= 0) {
    foundConversation.messages[foundMessageIndex] = {
      ...foundConversation.messages[foundMessageIndex],
      answered,
      answeredContent: answered_content
    };

    const foundConversationIndex = conversations.findIndex((c) => c.id === foundConversation.id);
    conversations[foundConversationIndex] = foundConversation;
  }

  return conversations;
};

// eslint-disable-next-line consistent-return
const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PATIENT_MESSAGE_SUCCESS:
      for (let i = 0; i < state.conversations.length; i++) {
        if (action.selectedPatientId === state.conversations[i].id) {
          return {
            ...state,
            patientMessage: state.conversations[i]
          };
        }
      }
      break;

    case actionTypes.SOCKET_CONNECT:
      return { ...state, socketConnected: action.connected };

    case actionTypes.CHANGE_TEXT_MESSAGE:
      return { ...state, messageContent: action.value };

    case actionTypes.SEND_MESSAGE:
      return { ...state };

    case actionTypes.SEND_MESSAGE_OBJECT:
      return { ...state };

    case actionTypes.SAVED_MESSAGES_RECEIVED:
      return state;

    case actionTypes.MESSAGE_RECEIVED:
      return {
        ...state,
        conversations: buildMessages(state, action)
      };

    // Message Event
    case actionTypes.MESSAGE_EVENT_ANSWER:
      return {
        ...state,
        messageContent: action.content,
        conversations: getAnsweredMessageOnConversations(state, action)
      };

    case actionTypes.MESSAGE_UPDATED_RECEIVED:
      return {
        ...state,
        conversations: getMessageUpdatedOnConversations(state, action)
      };

    default:
      return { ...state };
  }
};

export default messageReducer;
