/* eslint-disable */
import * as actionTypes from 'src/actions/actionTypes';

const initialState = {
  collaborations: [],
  allCollaborations: []
};

const collaborationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PREP_SUCCESS:
      return {
        ...state,
        collaborations: action.collaborations
      };

    case actionTypes.GET_PREP_FAILURE:
      return {
        ...state
      };

    case actionTypes.FETCH_ALL_PREPS_SUCCESS:
      return {
        ...state,
        allCollaborations: action.data
      };

    default:
      return { ...state };
  }
};

export default collaborationReducer;
