/* eslint-disable */
import React, { useState } from 'react';
import './style.css';
import { TextField } from '@mui/material';

function LandingPage() {
  const [diagnosis, setDiagnosis] = useState('other');

  const handleSubmitStartButton = () => {
    if (diagnosis) {
      window.location.replace(
        `/client/houstoncolon?procedure=general_schedule_registration&diagnosis=${diagnosis}`
      );
    }
  };

  return (
    <div id="">
      <div className="">
        <div />

        <header
          style={{
            zIndex: 1000,
            position: 'absolute',
            top: 0
          }}
        >
          <div
            style={{
              transition: 'all 200ms ease-in-out',
              opacity: 1,
              zIndex: 1000
            }}
          >
            <div
              style={{
                'background-color': 'rgb(255, 255, 255)',

                'z-index': 100,
                'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 1px 4px'
              }}
              className="pos-f w100p brdb t-50 brd-transparent"
            >
              <div className="df jcsb df aic pl8 pr4 ph3-lg ph4-xs minh20 minh14-sm">
                <a
                  rel="home"
                  className="zIndex1"
                  style={{
                    'flex-shrink': 0,
                    position: 'absolute',
                    left: 20,
                    top: 30
                  }}
                  data-savepage-href="/"
                  href="https://coloplan.com/"
                  data-cy="coloplan-logo"
                >
                  <img
                    className=" "
                    height="21"
                    width="186"
                    src="/static/assets/logo.png"
                    alt="ColoPlan"
                  />
                </a>
              </div>
              <div
                className=" "
                style={{
                  position: 'absolute',
                  right: 20,
                  top: 30
                }}
              >
                <ul className="dg aic gg2">
                  <li>
                    <a
                      className="wsnw brdn br8 font-im fs14 lh12"
                      style={{
                        'background-color': '#CDE7FF',
                        padding: 8
                      }}
                      href="/auth/login"
                    >
                      &nbsp;Log In&nbsp;
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>

        <main className="src-components-v5-Layout__main" id="main">
          <div
            style={{
              position: 'relative'
            }}
          >
            <div
              className="ph8"
              style={{
                'padding-top': '10rem',
                'padding-bottom': '10rem'
              }}
            >
              <span
                style={{
                  'padding-top': '10rem',
                  'box-sizing': 'border-box',
                  display: 'block',
                  overflow: 'hidden',
                  width: 'initial',
                  background: 'none',
                  opacity: 1,
                  border: 0,
                  margin: 0,
                  padding: 0,
                  position: 'absolute',
                  top: 60,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  height: 600
                }}
              >
                <img
                  alt=""
                  src="/static/assets/hero.jpg"
                  decoding="async"
                  data-nimg="fill"
                  className="pos-a top0 right0 bottom0 left0 minw100p"
                  style={{
                    padding: 0,
                    border: 'none',
                    margin: 'auto',
                    display: 'block',
                    width: 0,
                    height: 0,
                    'min-width': '100%',
                    'max-width': '100%',
                    'min-height': '100%',
                    'max-height': '100%',
                    'object-fit': 'cover',
                    'object-position': '0 10',
                    filter: 'brightness(40%)'
                  }}
                />
              </span>
              <div className="df ma jcsb aife pos-r fr">
                <div>
                  <h1 className="white maxw30 mb2 lh12 font-cm fs24-md">
                    Schedule your visit or procedure.
                  </h1>
                  <p className="fs28 fs18-md yellow font-cm lh14">
                    Book same day appointments.
                  </p>
                  <p>&nbsp;</p>
                  <span className="fs18 font-csb mb1 white-f tofe oh wsnw wsn-xs maxw20">
                    Please select the reason for your visit:
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      'justify-content': 'center',
                      'align-items': 'center'
                    }}
                  >
                    <TextField
                      className="basicSelect"
                      fullWidth
                      value={diagnosis}
                      onChange={(event) => setDiagnosis(event.target.value)}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ native: true }}
                      select
                      placeholder="Select Type of Visit"
                      style={{
                        background: 'white',
                        overflow: 'hidden'
                      }}
                    >
                      <option id="other" value="other">
                        Reason
                      </option>
                      <option id="anal_fissure" value="anal_fissure">
                        Anal Fissure
                      </option>
                      <option id="anal_fistula" value="anal_fistula">
                        Anal Fistula
                      </option>
                      <option id="anal_pain" value="anal_pain">
                        Anal Pain
                      </option>
                      <option id="anal_abscess" value="anal_abscess">
                        Anal Abscess
                      </option>
                      <option id="colorectal_cancer" value="colorectal_cancer">
                        Colorectal Cancer
                      </option>
                      <option id="colon_polyps" value="colon_polyps">
                        Colon Polyps
                      </option>
                      <option
                        id="colostomy_reversal"
                        value="colostomy_reversal"
                      >
                        Colostomy Reversal
                      </option>
                      <option id="colonoscopy" value="colonoscopy">
                        Colonoscopy
                      </option>
                      <option id="constipation" value="constipation">
                        Constipation
                      </option>
                      <option id="crohns_disease" value="crohns_disease">
                        Crohn's Disease
                      </option>
                      <option id="diverticulitis" value="diverticulitis">
                        Diverticulitis
                      </option>
                      <option
                        id="endometriosis_of_the_bowel"
                        value="endometriosis_of_the_bowel"
                      >
                        Endometriosis of the Bowel
                      </option>
                      <option
                        id="fecal_incontinence"
                        value="fecal_incontinence"
                      >
                        Fecal Incontinence
                      </option>
                      <option id="hemorrhoids" value="hemorrhoids">
                        Hemorrhoids
                      </option>
                      <option id="hra" value="hra">
                        HRA
                      </option>
                      <option id="pilonidal_cyst" value="pilonidal_cyst">
                        Pilonidal Cyst
                      </option>
                      <option id="rectal_bleeding" value="rectal_bleeding">
                        Rectal Bleeding
                      </option>
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      <option
                        id="ulcerative_colitis"
                        value="ulcerative_colitis"
                      >
                        Ulcerative Colitis
                      </option>
                    </TextField>
                    <button
                      type="submit"
                      className="basicSubmit"
                      onClick={handleSubmitStartButton}
                      style={{
                        float: 'right'
                      }}
                    >
                      Start
                    </button>
                  </div>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default LandingPage;
