import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { colors } from '@mui/material';
import mock from 'src/utils/mock';

mock.onGet('/api/calendar').reply(200, {
  draft: [],
  events: [
    {
      id: uuid(),
      title: 'Samantha Reaves',
      desc: 'Colonoscopy RT1',
      color: colors.green['700'],
      start: moment('2020-03-01 16:55:00'),
      end: moment('2020-03-01 17:02:00')
    },
    {
      id: uuid(),
      title: 'Jabob Sumnars',
      desc: 'Colonoscopy RT7',
      start: moment('2020-03-03 08:55:00'),
      end: moment('2020-03-04 15:02:00')
    },
    {
      id: uuid(),
      title: 'Bilal Hasnat',
      desc: 'Prepare documentation',
      allDay: true,
      start: moment('2020-03-14 16:55:00')
    },
    {
      id: uuid(),
      title: 'Erica Troy',
      desc: 'Colonoscopy RT7',
      allDay: true,
      start: moment('2020-03-18 07:00:00')
    },
    {
      id: uuid(),
      title: 'Jaun Mendez',
      desc: 'Colonoscopy RT1',
      color: colors.green['700'],
      start: moment('2020-03-20 08:55:00'),
      end: moment('2020-03-20 09:30:00')
    },
    {
      id: uuid(),
      title: 'Alex Lee',
      desc: 'Colonoscopy RT1',
      allDay: true,
      start: moment('2020-03-30 08:00:00')
    },
    {
      id: uuid(),
      title: 'Alexandra Coralis',
      color: colors.green['700'],
      desc: 'Colonoscopy RT1',
      start: moment('2020-03-30 08:00:00'),
      end: moment('2020-03-30 09:00:00')
    }
  ]
});
