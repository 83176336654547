/* eslint-disable */
import React, { Suspense, useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getCollaborations, updateToastConfig } from 'src/actions';
import AuthGuard from 'src/components/AuthGuard';

import NavBar from './NavBar';
import TopBar from './TopBar';
import Toast from '../../components/Toast';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 0
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0
    }
  }
}));

const navSetting = false;

function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const collaborations = useSelector(
    (state) => state.collaboration.collaborations
  );
  const location = useLocation();
  const from = new URLSearchParams(location.search).get('from');

  const { toastConfig } = useSelector((state) => state.common);
  const handleCloseToast = () => {
    dispatch(updateToastConfig({ ...toastConfig, open: false }));
  };

  useEffect(() => {
    dispatch(getCollaborations(from));
  }, [dispatch, from]);

  return (
    <div>
      <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      {navSetting && (
        <NavBar
          onMobileClose={() => setOpenNavBarMobile(false)}
          openMobile={openNavBarMobile}
          collaborations={collaborations}
        />
      )}
      <div className={classes.container}>
        <div className={classes.content}>
          <AuthGuard roles={['ADMIN', 'DOCTOR', 'NURSE', 'PATIENT']}>
            <Suspense fallback={<LinearProgress />}>
              <Outlet />
            </Suspense>
          </AuthGuard>
        </div>
      </div>
      <Toast
        open={toastConfig.open}
        onClose={handleCloseToast}
        message={toastConfig.message}
        type={toastConfig.type}
      />
    </div>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
