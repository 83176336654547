import { prepService } from 'src/services';
import * as actionTypes from './actionTypes';
import { getCollaborationById } from './prepActions';

export const answer = ({ queryId, answerValue, messageLocalId }) => (dispatch) => {
  dispatch({
    type: actionTypes.MESSAGE_EVENT_ANSWER,
    content: { queryId, answerValue, messageLocalId }
  });
};

export const updateMessageEventsById = (messageEvent) => (dispatch) => {
  prepService.updateMessageEventById(messageEvent)
    .then(({ data }) => {
      dispatch(getCollaborationById(data.collaborationId));
    });
};

export default {};
