import React from 'react';
import {
  Paper
} from '@mui/material';
import RichEditor from 'src/components/RichEditor';

function Test() {
  return (
    <div>
      <Paper>
        <RichEditor />
      </Paper>
    </div>
  );
}

export default Test;
