import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import prepReducer from './prepReducer';
import collaborationReducer from './collaborationReducer';
import patientReducer from './patientReducer';
import commonReducer from './commonReducer';
import messageReducer from './messageReducer';
import accountReducer from './accountReducer';
import notificationReducer from './notificationReducer';
import doctorReducer from './doctorReducer';
import appointmentReducer from './appointmentReducer';
import reviewReducer from './reviewReducer';
import surgeryReducer from './surgeryReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  prep: prepReducer,
  collaboration: collaborationReducer,
  patient: patientReducer,
  common: commonReducer,
  message: messageReducer,
  account: accountReducer,
  notifications: notificationReducer,
  referral: doctorReducer,
  appointment: appointmentReducer,
  review: reviewReducer,
  surgery: surgeryReducer,
});

export default rootReducer;
