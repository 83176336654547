/* eslint-disable */
import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  businessLocation: stringFormatter.formatUrl('/api/businessLocation'),
  provider: stringFormatter.formatUrl('/users/providers'),
  business: stringFormatter.formatUrl('/business'),
  verifyQuestion: stringFormatter.formatUrl('/api/verifyQuestion'),
  affiliation: stringFormatter.formatUrl('/affiliation'),
  businessLocationUnauth: stringFormatter.formatUrl('/api/unauth/businessLocation'),
  collaborationsUnauth: stringFormatter.formatUrl('/api/unauth/collaboration/business'),
  collaborationUnauth: stringFormatter.formatUrl('/api/unauth/collaboration'),
  providerUnauth: stringFormatter.formatUrl('/api/unauth/provider'),
  renderPatientFormUnauth: stringFormatter.formatUrl('/api/unauth/render-patient-form'),
  publicPatientFormUnauth: stringFormatter.formatUrl('/api/unauth/public-patient-form'),
  patientInfoUnauth: stringFormatter.formatUrl('/api/unauth/public-patient-info'),
  basicDoctorInfoUnauth: stringFormatter.formatUrl('/api/unauth/doctor-info'),
  facilitiesUnauth: stringFormatter.formatUrl('/api/unauth/facilities'),
  calendar: stringFormatter.formatUrl('/api/calendar/public-calendar'),
  userCalendar: stringFormatter.formatUrl('/api/userCalendar/private-calendar'),
  medicalHistory: stringFormatter.formatUrl('/api/medicalHistory'),
  medicine: stringFormatter.formatUrl('/api/medicine'),
  doctor: stringFormatter.formatUrl('/api/doctor'),
  exportDoctor: stringFormatter.formatUrl('/api/doctor/export'),
  checkPatientInfo: stringFormatter.formatUrl('/api/unauth/check-patient'),
  diagnosisCalendar: stringFormatter.formatUrl('/api/unauth/diagnosisCalendar'),
  diagnosisCalendarAvailable: stringFormatter.formatUrl('/api/unauth/diagnosisCalendarAvailable'),
  incompleteForm: stringFormatter.formatUrl('/api/incompleteForm'),
  officeLocation: stringFormatter.formatUrl('/api/officeLocation'),
  updateProviderCalendar: stringFormatter.formatUrl('/api/unauth/updateProviderCalendar'),
  providerCalendar: stringFormatter.formatUrl('/api/unauth/providerCalendar'),
  providerCalendarList: stringFormatter.formatUrl('/api/unauth/providerCalendarList'),
  activeCalendar: stringFormatter.formatUrl('/api/unauth/active-calendar')
};

const getFacilityAddresses = () => axiosInstance.get(endpoints.businessLocation);

const getFacilityAddressesUnauth = (businessId) => axiosInstance.get(`${endpoints.businessLocationUnauth}/${businessId}`);

const getCollaborationsUnauth = (businessId) => axiosInstance.get(`${endpoints.collaborationsUnauth}/${businessId}`);

const getCollaborationUnauth = (collaborationId) => axiosInstance.get(`${endpoints.collaborationUnauth}/${collaborationId}`);

const getProviderUnauth = (businessId) => axiosInstance.get(`${endpoints.providerUnauth}/${businessId}`);

const getFacilityNames = () => axiosInstance.get(endpoints.business);

const getAffiliations = () => axiosInstance.get(endpoints.affiliation);

const getProviders = () => axiosInstance.get(endpoints.provider);

const getBusiness = (businessId) => axiosInstance.get(`${endpoints.business}/${businessId}`);

const getAllBusiness = () => axiosInstance.get(`${endpoints.business}`);

const fetchCalendarEvents = (facilityId) => axiosInstance.get(`${endpoints.calendar}/${facilityId}`);

const fetchUserCalendarEvents = (facility) => axiosInstance.get(`${endpoints.userCalendar}/${facility}`);

const putUserCalendarEvent = (changeData) => axiosInstance.put(endpoints.userCalendar, changeData);

const renderPatientFormUnauth = (collaborationId) => axiosInstance.get(`${endpoints.renderPatientFormUnauth}/${collaborationId}`);

const patientInfoUnauth = (patientId) => axiosInstance.get(`${endpoints.patientInfoUnauth}/${patientId}`);

const basicDoctorInfoUnauth = (emails) => axiosInstance.post(endpoints.basicDoctorInfoUnauth, emails);

const getFacilitiesUnauth = () => axiosInstance.get(endpoints.facilitiesUnauth);

// eslint-disable-next-line max-len
const createMedicalHistory = (medicalHistory) => axiosInstance.post(`${endpoints.medicalHistory}`, medicalHistory);

const getMedicalHistory = (patientId) => axiosInstance.get(`${endpoints.medicalHistory}/patient/${patientId}`);

const getPublicPatientForm = (collaboration) => axiosInstance.get(`${endpoints.publicPatientFormUnauth}/${collaboration}`);

const getMedicine = () => axiosInstance.get(`${endpoints.medicine}`);

const getDoctor = () => axiosInstance.get(`${endpoints.doctor}`);

const getExportDoctors = () => axiosInstance.get(`${endpoints.exportDoctor}`);

const checkPatientInfo = (data) => axiosInstance.post(endpoints.checkPatientInfo, data);

const savingIncompleteForm = (data) => axiosInstance.post(endpoints.incompleteForm, data);

const getIncompleteForm = (id) => axiosInstance.get(`${endpoints.incompleteForm}/${id}`);

const notifyIncompleteForm = (data) => axiosInstance.post(endpoints.incompleteForm+'/saved', data);

const getOfficeLocation = (businessId) => axiosInstance.get(`${endpoints.officeLocation}/${businessId}`);

const getUnavailableSlotsByDiagnosis = (diagnosis) => axiosInstance.get(`${endpoints.diagnosisCalendar}/${diagnosis}`);

const getAllUnavailableSlot = () => axiosInstance.get(`${endpoints.diagnosisCalendar}`);
const getAllAvailableSlot = () => axiosInstance.get(`${endpoints.diagnosisCalendarAvailable}`);

const updateUnavailableSlotsByDiagnosis = (diagnosis, deniedTime, doctor) => axiosInstance.put(`${endpoints.diagnosisCalendar}`, { id: diagnosis, deniedTime, doctor});

const updateProviderCalendar = (data) => axiosInstance.put(`${endpoints.updateProviderCalendar}`, data);

const providerCalendar = (data) => axiosInstance.post(`${endpoints.providerCalendar}`, data);

const getActiveCalendar = (data) => axiosInstance.post(`${endpoints.activeCalendar}`, data);

const providerCalendarList = (data) => axiosInstance.post(`${endpoints.providerCalendarList}`, data);

const twoFactorAuth = (data) => axiosInstance.post(`${endpoints.twoFactorAuth}`, data);
// eslint-disable-next-line import/prefer-default-export
export const commonService = {
  getFacilityAddresses,
  getFacilityNames,
  getAffiliations,
  getProviders,
  getBusiness,
  getAllBusiness,
  getFacilityAddressesUnauth,
  getCollaborationsUnauth,
  getProviderUnauth,
  fetchCalendarEvents,
  getCollaborationUnauth,
  renderPatientFormUnauth,
  getPublicPatientForm,
  fetchUserCalendarEvents,
  putUserCalendarEvent,
  patientInfoUnauth,
  basicDoctorInfoUnauth,
  createMedicalHistory,
  getMedicalHistory,
  getMedicine,
  getDoctor,
  getExportDoctors,
  getFacilitiesUnauth,
  checkPatientInfo,
  savingIncompleteForm,
  getIncompleteForm,
  notifyIncompleteForm,
  getOfficeLocation,
  getUnavailableSlotsByDiagnosis,
  getAllUnavailableSlot,
  updateUnavailableSlotsByDiagnosis,
  updateProviderCalendar,
  providerCalendar,
  providerCalendarList,
  twoFactorAuth,
  getAllAvailableSlot,
  getActiveCalendar
};
