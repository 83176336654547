import moment from 'moment';

export const initialState = {
  queryTypes: [],
  createPrepMessage: '',
  selectedPrep: '',
  hasServerError: false,
  serverErrorMessage: '',
  prep: {
    localId: '',
    name: '',
    businessId: '',
    description: '',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
    free: 0,
    privacyAllowClinicalTeamToInviteOthers: false,
    privacyDoubleBlindStudy: false,
    welcomeSMS: '',
    messageEvents: [
      {
        localId: '',
        collaborationId: '',
        day: 0,
        time: new Date(),
        qrLabel: '',
        qrStep: 1,
        qrInstruction: '',
        attachedImage: '',
        isActivated: false,
        segment: '0',
        repeatDay: 0,
        procedureTimeType: 1,
        procedureTimeMarkers: '9:00 AM',
        messageEventQuery: {
          localId: '',
          messageEventId: '',
          messageEventQueryValue: '',
          queryTypeId: '',
          content: '',
          query: '',
          level: 1,
          children: [],
          smsQuery: {
            type: 0,
            question: '',
            options: []
          }
        }
      }
    ],
    showUploadThumbnailModal: false,
    prepForm: {},
    isReBuildNodes: false
  },
  createPrepFormInfo: {
    showSuccessToast: false,
    hasError: false,
    message: '',
    loading: false
  },
  listPatientChanged: true
};

export default {};
