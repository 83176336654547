/* eslint-disable */
import * as actionTypes from 'src/actions/actionTypes';

const initialState = {
  profile: {
    localId: '',
    businessId: '',
    isDoctor: false,
    email: '',
    firstName: '',
    lastName: '',
    mobileNumber: '',
    role: '',
    avatar: '',
    telehealthLink: ''
  },
  password: {
    oldPassword: '',
    newPassword: ''
  },
  isUpdateSuccess: false,
  turnOnModal: false,
  isUpdateProfileFailed: false,
  updateProfileFailMessage: ''
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const profile = {
        localId: action.account.localId,
        businessId: action.account.businessId,
        isDoctor: action.account.isDoctor,
        email: action.account.email,
        firstName: action.account.firstName,
        lastName: action.account.lastName,
        mobileNumber: action.account.mobileNumber,
        role: action.account.role,
        avatar: action.account.avatar,
        userType: action.account.userType,
        telehealthLink: action.account.telehealthLink
      };
      return {
        ...state,
        profile
      };

    case actionTypes.CHANGE_TEXT_PROFILE:
      // eslint-disable-next-line no-case-declarations
      const { isChangeProfile, name, value } = action;
      if (isChangeProfile) {
        // eslint-disable-next-line no-param-reassign
        state.profile = {
          ...state.profile,
          [name]: value
        };
        return { ...state };
      }

      // eslint-disable-next-line no-param-reassign
      state.password = {
        ...state.password,
        [name]: value
      };
      return { ...state };

    case actionTypes.CLICK_SAVE_CHANGE_PROFILE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newProfile = {
        localId: action.profile.localId,
        businessId: action.profile.businessId,
        isDoctor: action.profile.isDoctor,
        email: action.profile.email,
        firstName: action.profile.firstName,
        lastName: action.profile.lastName,
        mobileNumber: action.profile.mobileNumber,
        role: action.profile.role,
        userType: action.profile.userType,
        avatar: action.profile.avatar
      };
      // eslint-disable-next-line no-param-reassign
      state.isUpdateSuccess = true;
      // eslint-disable-next-line no-param-reassign
      state.profile = newProfile;
      return { ...state };

    case actionTypes.CLICK_SAVE_CHANGE_PROFILE_FAILURE:
      return {
        ...state,
        isUpdateSuccess: false
      };

    case actionTypes.TURN_ON_MODAL_CONFIRM:
      // eslint-disable-next-line no-param-reassign
      state.turnOnModal = true;
      return { ...state };

    case actionTypes.TURN_OFF_MODAL_CONFIRM:
      // eslint-disable-next-line no-param-reassign
      state.turnOnModal = false;
      return { ...state };

    case actionTypes.CHANGE_PROFILE_FAILURE:
      return {
        ...state,
        isUpdateProfileFailed: true,
        updateProfileFailMessage: action.message
      };

    default:
      return { ...state };
  }
};

export default accountReducer;
