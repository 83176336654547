/* eslint-disable */
import map24h from '../constant/timeInDay24h';

const { isEmpty, forEach, includes } = require('lodash-es');

export const filterDoctorEmail = (
  procedureAvailableSlots,
  telehealthAvailableSlots
) => {
  const emails = [];
  for (const [, value] of Object.entries(procedureAvailableSlots)) {
    if (!isEmpty(value)) {
      forEach(value, (item) => {
        forEach(item.startTimes, (startTime) => {
          if (startTime.doctor) {
            if (!includes(emails, startTime.doctor)) {
              emails.push(startTime.doctor);
            }
          }
        });
      });
    }
  }

  for (const [, value] of Object.entries(telehealthAvailableSlots)) {
    if (!isEmpty(value)) {
      forEach(value, (item) => {
        forEach(item.startTimes, (startTime) => {
          if (startTime.doctor) {
            if (!includes(emails, startTime.doctor)) {
              emails.push(startTime.doctor);
            }
          }
        });
      });
    }
  }

  return emails;
};

// eslint-disable-next-line max-len
export const filterScheduleDiagnosisDoctorEmail = (diagnosis, currentEmail) => {
  const emails = [];
  for (const [, value] of Object.entries(diagnosis)) {
    if (!isEmpty(value.availableSlots)) {
      forEach(value.availableSlots, (item) => {
        forEach(item.startTimes, (startTime) => {
          if (startTime.doctor) {
            if (!includes(emails, startTime.doctor) && !includes(currentEmail, startTime.doctor)) {
              emails.push(startTime.doctor);
            }
          }
        });
      });
    }
  }
  return emails;
};

export const checkDuplicateDoctor = () => {};

export const removeParam = (key, sourceURL) => {
  let rtn = sourceURL.split('?')[0];
  let param;

  let params_arr = [];
  const queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    params_arr = queryString.split('&');
    for (let i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split('=')[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length) {
      rtn = `${rtn}?${params_arr.join('&')}`;
    }
  }
  return rtn;
};

const calculateMessageEventTime = (message) => {
  let { procedureTimeHours } = message;
  let { procedureTimeMins } = message;
  if (
    message.procedureTimeType === 1 &&
    message.procedureTimeMarkers !== null &&
    message.immediately === 0
  ) {
    // time markers
    const time24h =
      map24h.get(message.procedureTimeMarkers) || message.procedureTimeMarkers;

    if (time24h !== undefined && time24h.includes(':')) {
      procedureTimeHours = Number(time24h.split(':')[0]);
      procedureTimeMins = Number(time24h.split(':')[1]);
    } else {
      procedureTimeHours = time24h;
      procedureTimeMins = 0;
    }
  }

  const { day } = message;
  const isNegative = message.procedureTimeIsNegative ? -1 : 1;
  if (day === 0 && procedureTimeHours === 0 && procedureTimeMins === 0) {
    return 0;
  }

  return (
    isNegative * day * 3600 +
    isNegative * (procedureTimeHours * 60 + procedureTimeMins)
  );
};

export const sortMessageEvents = (messageEvents) =>
  messageEvents.sort((message1, message2) => {
    const a = calculateMessageEventTime(message1);
    const b = calculateMessageEventTime(message2);

    if (a === 0) return -1;
    if (b === 0) return 1;
    return a - b;
  });
