/* eslint-disable max-len */
import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  saveAppoinments: stringFormatter.formatUrl('/api/appointment/save-all'),
  getReviewsByPrep: stringFormatter.formatUrl('/api/review/'),
  addReview: stringFormatter.formatUrl('/api/review/'),
  reviewTypeByProvider: stringFormatter.formatUrl('/api/review-url/provider/'),
};
const saveAppoinments = (appointments) => axiosInstance.post(`${endpoints.saveAppoinments}`, appointments);
const getReviewsByPrep = (prepId, page = 0, limit = 50, keyword = '', filter = '') => axiosInstance.get(`${endpoints.getReviewsByPrep}${prepId}?page=${page}&limit=${limit}&keyword=${keyword}&filter=${filter}`);
const createReview = (review) => axiosInstance.post(endpoints.addReview, review);
const reviewTypeByProvider = (providerId) => axiosInstance.get(`${endpoints.reviewTypeByProvider}${providerId}`);

export const reviewService = {
  saveAppoinments,
  getReviewsByPrep,
  createReview,
  reviewTypeByProvider
};

export default {};
