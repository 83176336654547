/* eslint-disable */
import axios from 'axios';
import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  login: stringFormatter.formatUrl('/users/login'),
  authLogin: stringFormatter.formatUrl('/users/auth-login'),
  register: stringFormatter.formatUrl('/users/register'),
  user: stringFormatter.formatUrl('/users/me'),
  userById: stringFormatter.formatUrl('/users/'),
  requestResetPassword: stringFormatter.formatUrl(
    '/users/request-reset-password'
  ),
  resetPassword: stringFormatter.formatUrl('/users/reset-password'),
  twoFactorAuth: stringFormatter.formatUrl('/users/two-factor-auth'),
  findByBusinessId: stringFormatter.formatUrl('/users/find-user-by-business'),

  // Admin-specific apis
  update: stringFormatter.formatUrl('/users/admin/update'),
  delete: stringFormatter.formatUrl('/users/admin/delete'),
  changePassword: stringFormatter.formatUrl('/users/admin/change-password')
};

const login = (email, password) => {
  const payload = { email, password };
  return axios.post(endpoints.login, payload);
};

const authLogin = (email, password) => {
  const payload = { email, password };
  return axios.post(endpoints.authLogin, payload);
};

const twoFactorAuth = (email, otp) => {
  const payload = { email, otp };
  return axios.post(endpoints.twoFactorAuth, payload);
};

const register = (
  isAdministrator,
  businessName,
  title,
  location,
  affiliation,
  firstName,
  lastName,
  email,
  mobileNumber,
  password,
  userType
) => {
  const payload = {
    // eslint-disable-next-line max-len
    isAdministrator,
    businessName,
    title,
    location,
    affiliation,
    firstName,
    lastName,
    email,
    mobileNumber,
    password,
    userType
  };
  return axios.post(endpoints.register, payload);
};
const update = (
  localId,
  businessName,
  title,
  location,
  affiliation,
  firstName,
  lastName,
  email,
  mobileNumber,
  userType,
  loginAttempts
) => {
  const payload = {
    // eslint-disable-next-line max-len
    localId,
    businessName,
    title,
    location,
    affiliation,
    firstName,
    lastName,
    email,
    mobileNumber,
    userType,
    loginAttempts
  };
  return axios.put(endpoints.update, payload);
};

const getUser = () => axiosInstance.get(endpoints.user);
const getUserById = (userId) =>
  axiosInstance.get(`${endpoints.userById}/${userId}`);
const requestResetPassword = (email, mobileNumber) => {
  const payload = { email, mobileNumber };
  return axios.post(endpoints.requestResetPassword, payload);
};

const resetPassword = (id, password, token) => {
  const payload = {
    id,
    password,
    token
  };

  return axios.post(endpoints.resetPassword, payload);
};

const getUserByBusinessId = (businessId) =>
  axios.get(`${endpoints.findByBusinessId}/${businessId}`);

const deleteUserById = (userId) =>
  axios.delete(`${endpoints.delete}/${userId}`);

const updatePasswordForUserByAdmin = (localId, newPassword) => {
  const payload = {
    localId,
    newPassword
  };
  return axios.put(endpoints.changePassword, payload);
};
// eslint-disable-next-line import/prefer-default-export
export const userService = {
  login,
  authLogin,
  register,
  update,
  getUser,
  getUserById,
  requestResetPassword,
  resetPassword,
  twoFactorAuth,
  getUserByBusinessId,
  deleteUserById,
  updatePasswordForUserByAdmin
};
