import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider as StoreProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { SocketHolder } from 'src/components/SocketHolder';
import GoogleAnalytics from './components/GoogleAnalytics';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/prismjs';
import './mixins/validate';
import './mock';
import routes from './routes';
import { configureStore } from './store';
import { theme } from './theme';
// import './assets/scss/main.scss';
const store = configureStore();

function App() {
  return (
    <StoreProvider store={store}>
      <SocketHolder />
      <ThemeProvider theme={theme}>
        <StylesProvider direction="ltr">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <RouterProvider router={routes}>
              <ScrollReset />
              <GoogleAnalytics />
            </RouterProvider>
          </LocalizationProvider>
        </StylesProvider>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
