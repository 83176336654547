/* eslint-disable */
import * as actionTypes from 'src/actions/actionTypes';

const initialState = {
  notifications: []
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_RECEIVED:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications]
      };

    case actionTypes.NOTIFICATION_RECEIVED_ALL:
      return {
        ...state,
        notifications: [...action.notifications]
      };

    default:
      return { ...state };
  }
};

export default notificationReducer;
