import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { LinearProgress } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden'
  }
}));

function Error() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
}

export default Error;
