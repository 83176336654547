/* eslint-disable max-len */
import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  patient: stringFormatter.formatUrl('/api/patient'),
  unauthPatient: stringFormatter.formatUrl('/api/unauth/new-patient'),
  updateUnauthPatient: stringFormatter.formatUrl('/api/unauth/patient'),
  getPatientsByPrep: stringFormatter.formatUrl('/api/patient/patient-table/'),
  getAppointmentsByPrep: stringFormatter.formatUrl('/api/appointment/'),
  getPatientById: stringFormatter.formatUrl('/api/patient/'),
  getVerifyQuestion: stringFormatter.formatUrl('/api/verifyQuestion/question/'),
  sendVerificationEmail: stringFormatter.formatUrl('/api/verifyQuestion/sendVerificationEmail/'),
  updateActiveTime: stringFormatter.formatUrl('/api/patient/active/'),
  updateEcw: stringFormatter.formatUrl('/api/patient/ecw/'),
  search: stringFormatter.formatUrl('/api/patient/search'),
  basicCompletedRegisterForm: stringFormatter.formatUrl('/api/unauth/basic-complete-register'),
  medicalHistory: stringFormatter.formatUrl('/api/medicalHistory'),
  sendOverdueEmail: stringFormatter.formatUrl('/api/appointment/sendOverdueEmail/'),
  sendEmailRegisterLink: stringFormatter.formatUrl('/api/unauth/register-later'),
};

const createPatient = (patient) => axiosInstance.post(endpoints.patient, patient);
const createPatientUnauth = (patient) => axiosInstance.post(endpoints.unauthPatient, patient);
const updatePatientUnauth = (patient) => axiosInstance.put(endpoints.updateUnauthPatient, patient);
const updatePatient = (patient) => axiosInstance.put(`${endpoints.patient}/${patient.localId}`, patient);
const deletePatient = (patient) => axiosInstance.delete(`${endpoints.patient}/${patient.localId}`);

const getPatientsByPrep = (prepId, page = 0, limit = 50, filterDate = '') => axiosInstance.get(`${endpoints.getPatientsByPrep}${prepId}?page=${page}&limit=${limit}&filterDate=${filterDate}`);
const getAppointmentsByPrep = (prepId, page = 0, limit = 50, keyword = '', filter = '') => axiosInstance.get(`${endpoints.getAppointmentsByPrep}${prepId}?page=${page}&limit=${limit}&keyword=${keyword}&filter=${filter}`);

const getPatientById = (Id) => axiosInstance.get(`${endpoints.getPatientById}${Id}`);
const getVerifyQuestion = (patientId) => axiosInstance.get(`${endpoints.getVerifyQuestion}${patientId}`);
const submitVerifyAnswer = (patientId, questionId, answer) => axiosInstance.post(`${endpoints.getVerifyQuestion}${patientId}`, { question: questionId, answer });
const sendVerificationEmail = (patientId, patientEmail) => axiosInstance.post(`${endpoints.sendVerificationEmail}${patientId}`, { patientEmail });
const updateActiveTime = (patientId) => axiosInstance.put(`${endpoints.updateActiveTime}${patientId}`, {});
const updateEcw = (patientId, userId) => axiosInstance.put(`${endpoints.updateEcw}${patientId}`, { userId });
const searchPatient = (keyword) => axiosInstance.post(`${endpoints.search}`, { keyword });
const sendEmailCompleteBasicRegister = (patientId, collaborationShortName) => axiosInstance.get(`${endpoints.basicCompletedRegisterForm}/${patientId}/${collaborationShortName}`);
const deleteMedicalHistoryOfPatient = (patientId) => axiosInstance.delete(`${endpoints.medicalHistory}/patient/${patientId}`);
const sendOverdueEmail = (appointmentId) => axiosInstance.post(`${endpoints.sendOverdueEmail}${appointmentId}`);
const sendEmailRegisterLink = (patientId, collaborationShortName) => axiosInstance.post(`${endpoints.sendEmailRegisterLink}`, { patientId, collaborationShortName });
export const patientService = {
  createPatient,
  createPatientUnauth,
  updatePatientUnauth,
  updatePatient,
  deletePatient,
  getPatientsByPrep,
  getAppointmentsByPrep,
  getPatientById,
  getVerifyQuestion,
  submitVerifyAnswer,
  sendVerificationEmail,
  updateActiveTime,
  updateEcw,
  searchPatient,
  sendEmailCompleteBasicRegister,
  deleteMedicalHistoryOfPatient,
  sendOverdueEmail,
  sendEmailRegisterLink
};

export default {};
